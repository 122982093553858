import React from 'react'

const Footer = (props) => {
    return(
        
        <div className="footerContent">
                    <div className="navDeco">
        <div className="navBarDeco1">
            </div>
            <div className="navBarDeco2">
            </div>
            <div className="navBarDeco3">
            </div>   
        </div>
            (c)2018 - Andrew Pedersen
        

     </div>

    )
}
export default Footer