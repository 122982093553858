import React from 'react'

const Mavbar = (props) => {
    return(
      <div>
      <div className="navBar">
        <div className="navDeco">
            <div className="navBarDeco1">
            </div>
            <div className="navBarDeco2">
            </div>
            <div className="navBarDeco3">
            </div>   
        </div>
      </div>
    </div>
    )
}
export default Mavbar